import { Drawer, TextInput } from "flowbite-react";
import { useState } from "react";
import { SearchIon } from "../../assets/icons";
import ProductCard from "../../elements/ProductCard";
import { HiSearch } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LoadingShopPart } from "../../general additions/LoadingElements";

export default function Search() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClose = () => setIsOpen(false);
  const [inputValue, setInputValue] = useState('');
  const [products, setProducts] = useState([]);

  const searchProducts = async (e) => {
    e.preventDefault();
    if (inputValue !== "") {
      try {
        setIsOpen(true);
        setLoading(true);
        const response = await axios.get(localhost + '/products/search', {
          params: {
            query: inputValue,
            language: i18n.language,
          },
        });
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("There was an error searching for products!", error);
        throw error;
      }
    }
  };

  return (
    <>
      <div className="header-search">
        <form onSubmit={searchProducts} className="grid grid-cols-6 gap-1">
          <TextInput type="search" value={inputValue} onChange={(e) => setInputValue(e.target.value)} className="col-span-5 myborder rounded-lg" placeholder={t("search-input")} size={32} required />
          <button type="button" onClick={searchProducts} className="bg-blue-600 text-white rounded-lg flex justify-center items-center"><HiSearch className="col-span-1 w-7 h-7" /></button>
        </form>
      </div>

      <Drawer open={isOpen} onClose={handleClose} position="bottom" className="p-0 h-[80vh]">
        <Drawer.Header title="Search result" titleIcon={() => <img src={SearchIon} className="w-[22px] h-auto mr-1" alt="" />} onClick={() => setIsOpen(!isOpen)}
          className="cursor-pointer px-4 pt-4 hover:bg-gray-50" />
        <Drawer.Items className="p-4">
          <div className=''>
            <div className="grid sm:grid-cols-2 md:mb-8 lg:grid-cols-3 xl:grid-cols-4 gap-3 mb-4 2xl:px-96 xl:px-64 lg:px-44 md:px-32 sm:px-16 px-4">
              {
                loading ?
                  (<div className="flex justify-center items-center min-h-[60vh] col-span-4"><LoadingShopPart sizeProp={120} /></div>)
                  : products.map((item, index) => (
                    <ProductCard key={index} data={item} typeProp="notJson" />
                  ))
              }
            </div>
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
