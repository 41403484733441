import React, { useEffect, useState } from 'react';
import AccountDetails from './AccountDetails';
import HistroryOrders from './HistroryOrders';
import { CreditCardIcon, ListBulletIcon, UserIcon } from '@heroicons/react/24/outline';
import Logout from './Logout';
import PaymentMethods from './PaymentMethods';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from '../../auth/getUserInfo';
import { useRefresh } from '../../general additions/RefreshProvider';

export default function SideMenu() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [menuActive, setMenuActive] = useState("dashboard");
  const { refreshKey } = useRefresh();

  const links = ["dashboard", "orders", "payment", "account-details"];
  const menu = [
    {
      title: "orders",
      icon: <ListBulletIcon className='inline h-8 w-8 text-gray-500 group-hover:text-blue-600' />
    },
    {
      title: "payment",
      icon: <CreditCardIcon className='inline h-8 w-8 text-gray-500 group-hover:text-blue-600' />
    },
    {
      title: "account-details",
      icon: <UserIcon className='inline h-8 w-8 text-gray-500 group-hover:text-blue-600' />
    }
  ];

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUserInfo();
      if (userInfo) {
        localStorage.setItem('uniqueID', userInfo.uniqueID);
        localStorage.setItem("username", userInfo.username);
        setLoading(false);
      } else {
        console.log(null);
      }
    };
    fetchUserInfo();
  }, [refreshKey]);

  const username = localStorage.getItem("username");

  return (
    <section>
      <div className="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
        <h1 className="border-b py-6 text-3xl text-gray-900 font-semibold">{t("account")}</h1>
        <div className="grid grid-cols-8 pt-3 sm:grid-cols-10">
          <div className="relative my-4 w-56 sm:hidden">
            <input className="peer hidden" type="checkbox" name="select-1" id="select-1" />
            <label htmlFor="select-1" className="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-blue-700 peer-checked:ring">{menuActive} </label>
            <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
            <ul className="max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
              {
                links.map((item, index) => (
                  <li key={index} onClick={() => setMenuActive(item)} className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">{t(item)}</li>
                ))
              }
            </ul>
          </div>

          <div className="col-span-2 hidden sm:block">
            <ul>
              {
                links.map((item, index) => (
                  <li key={index} onClick={() => setMenuActive(item)} className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 ${menuActive === item ? "border-l-blue-700 text-blue-700" : "border-transparent"}`}>{t(item)}</li>
                ))
              }
              <li className="mt-5 cursor-pointer border-l-2 px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 border-transparent"><Logout compo="Logout" /></li>
            </ul>
          </div>

          {/* Here : */}
          {
            menuActive === "dashboard" ? (
              <div className="col-span-8 pt-6 pb-12 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
                <div className="pt-4">
                  <h1 className="py-2 text-2xl text-gray-900 font-semibold">{t("account")}</h1>
                  <p className="font- text-slate-600">{t("hello")} <strong>{username}</strong></p>
                </div>
                <hr className="mt-4 mb-8" />

                <div>
                  <p className="font- text-slate-600 mb-5">{t("hello-1")}</p>

                  <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4">
                    {
                      menu.map((item, index) => (
                        <div key={index} onClick={() => setMenuActive(item.title)} className="group col-span-1 cursor-pointer rounded-lg bg-white p-8 hover:bg-gray-100">
                          <div className="mx-auto mb-2 flex h-[58px] max-h-[58px] w-[58px] max-w-[58px] items-center justify-center rounded-full bg-gray-200 group-hover:bg-blue-200 p-2">
                            {item.icon}
                          </div>
                          <div className="text-center font-medium text-gray-500 group-hover:text-blue-600">{t(item.title)}</div>
                        </div>
                      ))
                    }
                    <Logout compo="from-dashboard" />
                  </div>
                </div>
              </div>
            ) : menuActive === "orders" ? <HistroryOrders /> : menuActive === "payment" ? <PaymentMethods /> : <AccountDetails />
          }

        </div>
      </div>

    </section>
  )
}
