import { Drawer, TextInput } from "flowbite-react";
import { useState } from "react";
import { HiSearch } from "react-icons/hi";
import { MenuIon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

export default function Menu() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const links = [
    {
      name: "home",
      path: "/"
    },
    {
      name: "shop",
      path: "/shop"
    },
    {
      name: "about",
      path: "/about-us"
    },
    {
      name: "contact",
      path: "/contact-us"
    }
  ];

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="header-action-btn hidden max-md:block">
        <img src={MenuIon} className='h-7 w-7 mx-auto' alt="" />
        <p className="header-action-label">Menu</p>
      </button>

      <Drawer open={isOpen} onClose={handleClose}>
        <Drawer.Header title="MENU" titleIcon={() => <></>} />
        <Drawer.Items>
          <div className="flex h-full flex-col justify-between py-2">
            <div className="pb-3 md:hidden grid grid-cols-6 gap-1">
              <TextInput type="search" className="col-span-5 myborder rounded-lg" placeholder={t("search-input")} required size={32} />
              <button className="bg-blue-600 text-white rounded-lg flex justify-center items-center"><HiSearch className="col-span-1 w-7 h-7" /></button>
            </div>

            <hr className="my-3" />

            <div className="mt-3">
              <ul className="flex flex-col gap-4">
                {
                  links.map((item, index) => (
                    <li key={index}><a className="px-2 py-1 text-gray-900 hover:text-blue-600" href={item.path}>{item.name}</a></li>
                  ))
                }
              </ul>
            </div>
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
