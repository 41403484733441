import React from 'react'
import Header from '../additions/Header'
import Footer from '../additions/Footer'
import TopBar from '../additions/TopBar'

export default function ContactUs() {
  return (
    <section className="">
      <TopBar/>
      <Header />
      <div className='bg-gray-50 mb-8'>
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">Contact Us</h2>
          <p className="mb-6 lg:mb-16 font-light text-center text-gray-500 sm:text-xl">
            Get answers to all your questions you might have.
            We will answer any questions you may have about our online sales right here.
          </p>
          <form action="#_" className="space-y-8">
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
              <input type="email" id="email" className="shadow-sm bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5" placeholder="name@sharedsystem.com" required />
            </div>
            <div>
              <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900">Subject</label>
              <input type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-200 rounded-lg border border-gray-300 shadow-sm focus:ring-gray-500 focus:border-gray-500" placeholder="Let us know how we can help you" required />
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Your message</label>
              <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-200 rounded-lg shadow-sm border border-gray-300 focus:ring-gray-500 focus:border-gray-500" placeholder="Leave a comment..."></textarea>
            </div>
            <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-gray-900 sm:w-fit hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300">Send message</button>
          </form>
        </div>
      </div>
      <Footer />
    </section>
  )
}
