import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { Dropdown } from 'flowbite-react';
import { franceIcon, ukIcon } from '../../assets/icons';
import { useState, useEffect } from 'react';
import i18n from '../../i18n';

export default function TopBar() {
  // Load the initial language from localStorage or fallback to "en"
  const [currentLang, setCurrentLang] = useState(() => {
    return localStorage.getItem("language") || "en";
  });

  const languages = [
    {
      slung: "en",
      title: "English",
      icon: ukIcon
    },
    {
      slung: "fr",
      title: "Français",
      icon: franceIcon
    }
  ];

  useEffect(() => {
    // Set the language when the component mounts based on the value in localStorage
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  // Switching Languages :
  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    localStorage.setItem("language", lang);

    // window.location.reload();
  };

  return (
    <div className="flex items-center justify-center gap-x-6 bg-gray-900 text-white py-2 overflow-hidden">
      <div className='container flex justify-between items-center'>
        <div className="flex flex-wrap gap-5 text-sm leading-6">
          <div className='flex flex-wrap items-center gap-1'>
            <PhoneIcon className='h-5 w-auto text-white' /> +212 6 1212-5353
          </div>
          <div className='flex flex-wrap items-center gap-1'>
            <EnvelopeIcon className='h-5 w-auto text-white' /> Support@sharedsystem.com
          </div>
        </div>

        <Dropdown className="text-sm leading-6" label="" dismissOnClick={false} renderTrigger={() => (
          <button className='flex flex-wrap items-center gap-1 px-3 py-1.5'>
            <img src={currentLang === "en" ? ukIcon : franceIcon} className='w-6 h-auto' alt='' /> {currentLang === "en" ? "English" : "Français"}
          </button>
        )}>
          {
            languages.map((item, index) => (
              <Dropdown.Item key={index} onClick={() => switchLanguage(item.slung)} className='flex flex-wrap items-center gap-1'>
                  <img src={item.icon} className='w-6 h-auto' alt='' /> {item.title}
              </Dropdown.Item>
            ))
          }
        </Dropdown>
      </div>
    </div>
  )
}
