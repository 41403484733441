import React, { useEffect, useState } from 'react';
import ShoppingCart from '../../elements/ShoppingCart';
import Menu from './Menu';
import { PersonIon } from '../../assets/icons';
import Search from '../search/Search';
import { Dropdown } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import Logout from '../dashboard/Logout';
import { getUserInfo } from '../../auth/getUserInfo';
import { LoadingShopPart } from '../../general additions/LoadingElements';

export default function Header() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isNavActive, setIsNavActive] = useState(false);
  const [isOverlayActive, setIsOverlayActive] = useState(false);
  const [role, setRole] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUserInfo();
      if (userInfo) {
        localStorage.setItem('uniqueID', userInfo.uniqueID);
        localStorage.setItem("username", userInfo.username);
        setRole(userInfo.role);
        setLoading(false);
      } else {
        console.log(null);
      }
    };
    fetchUserInfo();
  }, []);
  
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  // const role = localStorage.getItem("role");

  const toggleNavbar = () => {
    setIsNavActive(!isNavActive);
    setIsOverlayActive(!isOverlayActive);
  };

  const links = [
    {
      name: "home",
      path: "/"
    },
    {
      name: "shop",
      path: "/shop"
    },
    {
      name: "about",
      path: "/about-us"
    },
    {
      name: "contact",
      path: "/contact-us"
    }
  ];

  return (
    <header className="header">
      <div className="container">

        <div className={`overlay ${isOverlayActive ? 'active' : ''}`} onClick={toggleNavbar}></div>
        <Search />

        <a href="/" className="logo">
          <h1 className='text-3xl text-center text-black'>Name's website</h1>
        </a>

        <div className="header-actions">

          {
            !token ?
              (<a href='/login' className="header-action-btn">
                <img src={PersonIon} className='h-7 w-7 max-md:w-6 max-md:h-6 mx-auto' alt="" />
                <p className="header-action-label">{t("login")}</p>
              </a>)
              : loading ?
                <div><LoadingShopPart sizeProp={50} /></div>
                : (<Dropdown label="sign" dismissOnClick={false} renderTrigger={() => (
                  <div className="header-action-btn flex flex-col justify-center items-center text-center cursor-pointer">
                    <img src={PersonIon} className='h-6 w-6 mx-auto' alt="" />
                    <p className="header-action-label">{username}</p>
                  </div>
                )}>
                  {
                    role === "admin" ? (<>
                      <Dropdown.Item href='/admin/dashboard'>Dashboard</Dropdown.Item>
                    </>)
                      : (<>
                        <Dropdown.Item href='/account'>Dashboard</Dropdown.Item>
                      </>)
                  }
                  <Dropdown.Item><Logout compo="text" text="Logout" /></Dropdown.Item>
                </Dropdown>)
          }

          <ShoppingCart />
          <Menu />

        </div>

        <nav className="navbar">

          <ul className="navbar-list">
            {
              links.map((item, index) => (
                <li key={index}><a href={item.path} className="navbar-link transition-all ease-in duration-200 hover:font-semibold">{t(item.name)}</a></li>
              ))
            }
          </ul>

        </nav>

      </div>
    </header>
  );
};