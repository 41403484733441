export const FormatDate = ({ dateString }) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const FormatDateForcomment = ({ dateString }) => {
  const date = new Date(dateString);

  // Options for formatting the date
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };

  return `${date.toLocaleDateString('en-US', options)}`;
};
