import React from 'react'
import { useTranslation } from 'react-i18next';

export default function ProcessOfPurchase() {
  const { t } = useTranslation();
  const elements = [
    {
      img: "https://allgoodkeys.com/wp-content/uploads/2022/05/1-1-1.png",
      title: "proc-title-1",
      detail: "proc-detail-1"
    },
    {
      img: "https://allgoodkeys.com/wp-content/uploads/2022/05/2-1.png",
      title: "proc-title-2",
      detail: "proc-detail-2"
    },
    {
      img: "https://allgoodkeys.com/wp-content/uploads/2022/05/3-3-1.png",
      title: "proc-title-3",
      detail: "proc-detail-3"
    },
  ];

  return (
    <section className="container py-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mb-14 text-center">
          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-px my-5 bg-gray-300 border-0" />
            <span className="text-lg max-md:text-base font-semibold absolute px-3 text-gray-900 -translate-x-1/2 bg-white left-1/2">{t("process-title")}</span>
          </div>
          <p className="text-sm font-normal text-gray-500 max-w-md md:max-w-3xl mx-auto">
            {t("process-detail")}
          </p>
        </div>
        <div className="flex justify-center items-center gap-x-5 gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8">

          {
            elements.map((item, index) => (
              <div key={index} className="relative w-full text-center max-md:max-w-sm max-md:mx-auto group md:w-2/5 lg:w-1/4">
                <img src={item.img} className='rounded-lg flex justify-center items-center w-60 h-auto mx-auto cursor-pointer transition-all duration-500' alt="" />
                <h4 className="text-lg font-medium text-gray-900 mb-3 capitalize">
                  {t(item.title)}
                </h4>
                <p className="text-sm font-normal text-gray-500">
                  {t(item.detail)}
                </p>
              </div>
            ))
          }

        </div>
      </div>
    </section>
  )
}
