import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const savedLanguage = localStorage.getItem('language') || 'en';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "welcome-table": "Here are all your products",
          "product": "Product",
          "name": "Product name",
          "price": "Price",
          "category": "Category",
          "ratings": "Ratings",
          "dateaddition": "Date of addition",
          "admin": "Admin",
          "view": "View",
          "edit": "Edit",
          "delete": "Delete",
          "titleBanner": "Click to add a product",
          "textBanner": "The administrator can add, update or delete products.",
          "shop-cart": "Shopping cart",
          "cart": "Cart",
          "checkout": "Checkout",
          "subtotal": "Subtotal",

          // Home :
          "login": "Login",
          "register": "Register",
          "home": "Home",
          "shop": "Shop",
          "about": "About",
          "search-input": "Search product...",
          "search-res": "Search result",
          "best-sellers": "Best Sellers",
          "sort": "Sort",
          "popular": "The most popular",
          "newest": "Newest",
          "inc-price": "Increasing price",
          "dec-price": "Decreasing price",

          "proc-title-1": "1. Add a product to the shopping cart",
          "proc-detail-1": "Use the quick browsing function to search for the software you need. Make your selection and add to your cart.",
          "proc-title-2": "2. Apply promocode at checkout",
          "proc-detail-2": "For extra savings, remember to check the homepage for any discount codes — Often, special promotions are running.",
          "proc-title-3": "3. Receive activation key & download link in mailbox",
          "proc-detail-3": "No frustrating shipping delays. Instead, get a download link in your mailbox. Install and start using your product right away.",
          "process-title": "Download your software in seconds",
          "process-detail": "Name's website has made the process of software download as easy as 1-2-3.",
          "serv-1": "Reliable Service",
          "serv-2": "Cheap prices",
          "serv-3": "Email delivery",
          "serv-4": "Global licenses",
          "det-serv-1": "All Goods are checked manually to ensure maximal efficacy.",
          "det-serv-2": "All Our software keys are substantially cheaper than our competitors.",
          "det-serv-3": "Receive your software keys within seconds. No delays.",
          "det-serv-4": "No region restrictions on products purchased.",
          "testimonials": "Testimonials",
          "testimonials-1": "Hear What Our Happy Customers Have to Say",

          // categories :
          "All-products": "All products",
          "Microsoft-office": "Microsoft office",
          "Office-products": "Office products",
          "Operating-system": "Operating system",
          "Windows-servers": "Windows servers",
          "Antivirus-security": "Antivirus & security",
          "Bundles": "Bundles",

          "upload-image1": "Click to upload",
          "upload-image2": "the product image",
          "add-prod": "Add product",
          "edit-prod": "Edit product",

          // Review :
          "addreview": "Add a review",
          "outof": "out of",
          "review-desc": "The comment",
          "cancel": "Cancel",
          "reviews": "Reviews",
          "addfav": "Add to my favorites",
          "nofav": "Remove from my favorites",
          "addcart": "Add to cart",
          "prolist-1": "Instant digital download",
          "prolist-2": "Lifetime support",
          "writereview": "Write a review",
          "viewmore": "View more reviews",
          "viewless": "View less reviews",

          // other :
          "removeorder": "Remove the product",
          "removeorder-1": "Are you sure you want to remove this product?",
          "removeprod": "Remove the product",
          "removeprod-1": "Are you sure you want to remove this product?",
          "yes": "Yes",

          // Shop :
          "our-products": "Our Products",
          "product-categories": "Product Categories",
          "available": "Available",
          "unavailable": "Unavailable",

          // Account setting :
          "account": "Account",
          "hello": "Hello",
          "hello-1": "From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.",
          "orders": "Orders",
          "dashboard": "Dashboard",
          "payment": "Payment methods",
          "account-details": "Account details",

          "all-orders": "All my orders",
          "all-orders-1": "No order has been made yet.",
          "all-orders-2": "Browse products",

          "add-payment": "Add payment method",

          "fullname":"Full name",
          "fname": "First name",
          "lname": "Last name",
          "save": "Save",
          "leave-blank": "leave blank to leave unchanged",

          "username": "Username",
          "edit-username": "Your current username is",
          "new-name":"You can add the full name here",
          "edit-name": "Your current full name is",
          "edit-email": "Email Address",
          "edit-email-1": "Your current email is",
          "edit-password": "Password change",
          "edit-password-1": "Do you want to change your",
          "edit-password-2": "current password",
          "current-password": "Current password",
          "new-password": "New password",
          "conf-new-password": "Confirm new password",
          "error-not-match":"Passwords do not match",
          "error-username": "The username has already been taken",

        }
      },
      fr: {
        translation: {
          "welcome-table": "Voici tous vos produits",
          "product": "Produit",
          "name": "Nom du produit",
          "price": "Prix",
          "category": "Catégorie",
          "ratings": "Notes",
          "dateaddition": "Date d'ajout",
          "admin": "Administrateur",
          "view": "Voir",
          "edit": "Éditer",
          "delete": "Supprimer",
          "titleBanner": "Cliquez pour ajouter un produit",
          "textBanner": "L'administrateur peut ajouter, mettre à jour ou supprimer des produits.",
          "shop-cart": "Panier d'achats",
          "cart": "Panier",
          "checkout": "Sortie de caisse",
          "subtotal": "Sous total",

          // Home :
          "login": "S'identifier",
          "register": "S'inscrire",
          "home": "Accueil",
          "shop": "Boutique",
          "about": "À propos de",
          "search-input": "Rechercher un produit...",
          "search-res": "Résultats de la recherche",
          "best-sellers": "Meilleures ventes",
          "sort": "Trier",
          "popular": "Les plus populaires",
          "newest": "Le plus récent",
          "inc-price": "Prix croissant",
          "dec-price": "Prix en baisse",
          "proc-title-1": "1. Ajoutez un produit au panier",
          "proc-detail-1": "Utilisez la fonction de navigation rapide pour rechercher le logiciel dont vous avez besoin. Faites votre sélection et ajoutez-la à votre panier.",
          "proc-title-2": "2. Appliquez le code promo lors du paiement",
          "proc-detail-2": "Pour des économies supplémentaires, pensez à vérifier la page d'accueil pour tout code de réduction — Souvent, des promotions spéciales sont en cours.",
          "proc-title-3": "3. Recevez la clé d'activation et le lien de téléchargement dans votre boîte mail",
          "proc-detail-3": "Pas de retards d'expédition frustrants. Recevez un lien de téléchargement dans votre boîte mail. Installez et commencez à utiliser votre produit immédiatement.",
          "process-title": "Téléchargez votre logiciel en quelques secondes",
          "process-detail": "Le site web de Name a rendu le processus de téléchargement de logiciels aussi facile que 1-2-3.",
          "serv-1": "Service fiable",
          "serv-2": "Prix bas",
          "serv-3": "Livraison par e-mail",
          "serv-4": "Licences globales",
          "det-serv-1": "Toutes les marchandises sont contrôlées manuellement pour garantir une efficacité maximale.",
          "det-serv-2": "Toutes les clés de nos logiciels sont nettement moins chères que celles de nos concurrents.",
          "det-serv-3": "Recevez vos clés logicielles en quelques secondes. Aucun délai.",
          "det-serv-4": "Aucune restriction régionale sur les produits achetés.",
          "testimonials": "Témoignages",
          "testimonials-1": "Écoutez ce que nos clients satisfaits ont à dire",

          // categories :
          "All-products": "Tous les produits",
          "Microsoft-office": "Microsoft office",
          "Office-products": "Produits de office",
          "Operating-system": "Système d'exploitation",
          "Windows-servers": "Serveurs Windows",
          "Antivirus-security": "Antivirus et sécurité",
          "Bundles": "Collection",

          "upload-image1": "Cliquez pour télécharger",
          "upload-image2": "l'image du produit",
          "add-prod": "Ajouter un produit",
          "edit-prod": "Éditer le produit",

          //Review 
          "addreview": "Ajouter un commentaire",
          "outof": "sur",
          "review-desc": "Le commentaire",
          "cancel": "Annuler",
          "reviews": "Commentaires",
          "addfav": "Ajouter à mes favoris",
          "nofav": "Retirer de mes favoris",
          "addcart": "Ajouter au panier",
          "prolist-1": "Téléchargement numérique instantané",
          "prolist-2": "Soutien à vie",
          "writereview": "Écrire un commentaire",
          "viewmore": "Voir d'autres commentaires",
          "viewless": "Voir moins de commentaires",

          // other :
          "removeorder": "Retirer le produit",
          "removeorder-1": "Êtes-vous sûr de vouloir retirer ce produit?",
          "removeprod": "Retirer le produit",
          "removeprod-1": "Êtes-vous sûr de vouloir supprimer ce produit?",
          "yes": "Oui",

          // Shop :
          "our-products": "Nos produits",
          "product-categories": "Catégories de produits",
          "available": "Disponible",
          "unavailable": "Indisponible",

          // Account setting :
          "account": "Compte",
          "hello": "Bonjour",
          "hello-1": "Depuis le tableau de bord de votre compte, vous pouvez voir vos commandes récentes, gérer vos adresses de livraison et de facturation, et modifier votre mot de passe et les détails de votre compte.",
          "orders": "Commandes",
          "dashboard": "Tableau de bord",
          "payment": "Moyens de paiement",
          "account-details": "Détails du compte",

          "all-orders": "Toutes mes commandes",
          "all-orders-1": "Aucune commande n'a encore été passée.",
          "all-orders-2": "Parcourir les produits",

          "add-payment": "Ajouter un moyen de paiement",

          "fullname":"Nom complet",
          "fname": "Prénom",
          "lname": "Nom de famille",
          "save": "Enregistrer",
          "leave-blank": "laisser vide pour ne pas changer",
          "username": "Nom d'utilisateur",
          "edit-username": "Votre nom d'utilisateur actuel est",
          "new-name":"Vous pouvez ajouter le nom complet ici",
          "edit-name": "Votre nom complet actuel est",
          "edit-email": "Adresse e-mail",
          "edit-email-1": "Votre e-mail actuel est",
          "edit-password": "Changement de mot de passe",
          "edit-password-1": "Voulez-vous changer votre",
          "edit-password-2": "mot de passe actuel?",
          "current-password": "Mot de passe actuel",
          "new-password": "Nouveau mot de passe",
          "conf-new-password": "Confirmer le nouveau mot de passe",
          "error-not-match":"Les mots de passe ne correspondent pas",
          "error-username": "Le nom d'utilisateur a déjà été utilisé",
          
        }
      }
    },
    lng: savedLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // React already escapes data by default
    }
  });

export default i18n;
