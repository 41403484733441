import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconFill } from '@heroicons/react/24/solid';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useRefresh } from '../general additions/RefreshProvider';

export default function FavoriteBtn({ prodID, typeBtn }) {
  const { t } = useTranslation();
  const { triggerRefresh } = useRefresh();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const uniqueID = localStorage.getItem("uniqueID");
  const token = localStorage.getItem("token");
  const [ref, setRef] = useState(false);
  const [isFavorite, setIsFavorite] = useState("no");

  useEffect(() => {
    axios.post(localhost + "/isfavorite/" + prodID, { "uniqueID": uniqueID }, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setIsFavorite(res.data);
      }).catch((err) => console.error(err));
  }, [ref]);

  const handleFavorite = () => {
    const favData = { 'uniqueID': uniqueID, 'product_id': prodID };
    axios.post(localhost + "/favorites", favData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        triggerRefresh();
      }).catch((err) => console.error(err));
    setRef(!ref);
  }

  if (typeBtn === "icon") {
    return (
      <button onClick={handleFavorite} className="rounded-lg p-2 hover:bg-gray-100 text-red-700 hover:text-red-800">
        <span className="sr-only">Add to Favorites</span>
        {
          isFavorite === "no" ?
            <HeartIcon className="w-5 h-5" />
            : <HeartIconFill className="w-5 h-5" />
        }
      </button>
    )
  } else {
    return (
      <button onClick={handleFavorite} className="flex items-center justify-center py-2.5 px-5 text-[12px] font-medium bg-white border border-gray-200 rounded-lg text-red-700 hover:bg-gray-100 hover:text-red-800">
        {
          isFavorite === "no" ?
            <HeartIcon className="w-5 h-5 mr-2" />
            : <HeartIconFill className="w-5 h-5 mr-2" />
        }
        {isFavorite === "no" ? t("addfav") : t("nofav")}
      </button>
    )
  }
}
