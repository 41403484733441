import { facebookIcon, instagramIcon, pinterestIcon, twitterIcon } from "../../assets/icons";

export default function Footer() {
  const paymentsLogos = [
    "https://allgoodkeys.com/wp-content/uploads/2022/10/stripe-icon-1.png",
    "https://allgoodkeys.com/wp-content/uploads/2022/10/paypal-icon-1.png"
  ];

  return (
    <footer className="footer bg-gray-900 text-white">

      <div className="footer-top">
        <div className="container">

          <div className="footer-brand">

            <a href="#_" className="logo">
              <h1 className='text-3xl font-bold'>Name's website</h1>
            </a>

            <p className="footer-text">
              All Good Keys Ltd
              71-75 Shelton Street
              WC2H 9JQ, London
              United Kingdom
              Call us: +1 (202) 552-0420
              Email us: sales@sharedsystem.com
            </p>

            <ul className="social-list">

              <li><a href="#_" className="social-link">
                <img src={facebookIcon} className="h-5 w-5" alt="" />
              </a></li>

              <li><a href="#_" className="social-link">
                <img src={twitterIcon} className="h-5 w-5" alt="" />
              </a></li>

              <li><a href="#_" className="social-link">
                <img src={instagramIcon} className="h-5 w-5" alt="" />
              </a></li>

              <li><a href="#_" className="social-link">
                <img src={pinterestIcon} className="h-5 w-5" alt="" />
              </a></li>

            </ul>

          </div>

          <ul className="footer-list">

            <li><p className="footer-list-title">Company</p></li>
            <li><a href="#_" className="footer-link">About Us</a></li>
            <li><a href="#_" className="footer-link">Contact Us</a></li>
            <li><a href="#_" className="footer-link">Privacy Policy</a></li>
            <li><a href="#_" className="footer-link">Terms & Conditions</a></li>
            <li><a href="#_" className="footer-link">Delivery & Payment</a></li>
            <li><a href="#_" className="footer-link">Refund & Returns Policy</a></li>
            <li><a href="#_" className="footer-link">Cookie Policy</a></li>
          </ul>

          <ul className="footer-list">

            <li><p className="footer-list-title">Help</p></li>
            <li><a href="#_" className="footer-link">FAQs</a></li>
            <li><a href="#_" className="footer-link">Help Center</a></li>
            <li><a href="#_" className="footer-link">Get Confirmation ID Online</a></li>
            <li><a href="#_" className="footer-link">How to activate Office by Phone? Activation Guide</a></li>
            <li><a href="#_" className="footer-link">How to activate Windows by phone? Activation Guide</a></li>
          </ul>

          <ul className="footer-list">

            <li><p className="footer-list-title">Partners</p></li>

            <li><img className="h-20 w-auto" src="https://allgoodkeys.com/wp-content/uploads/2022/06/All-Good-Keys-Microsoft-certified-partner.png" alt="" /></li>

          </ul>

        </div>
      </div>

      <div className="footer-bottom py-2">
        <div className="container">

          <p className="copyright">
            &copy; 2024 <a href="#_">Name's website</a>. All Rights Reserved
          </p>

          <ul className="footer-bottom-list">

            <div className="payment">
              <p className="payment-title">Secure payment with</p>

              <div className="flex flex-wrap gap-4">
                {
                  paymentsLogos.map((item, index) => (
                    <img key={index} src={item} alt="Online payment logos" className="h-8 w-auto" />
                  ))
                }
              </div>
            </div>

          </ul>



        </div>
      </div>

    </footer>
  )
}