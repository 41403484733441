import { CheckCircleIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import { officeIcon } from '../../assets/icons';
import { useEffect, useState } from 'react';
import Reviews from './Reviews';
import axios from 'axios';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import RichTextEditor from '@mantine/rte';
import RatingStars from '../../elements/RatingStars';
import AddCart from '../../elements/AddCart';
import FavoriteBtn from '../../elements/FavoriteBtn';
import { blankimage } from '../../assets/images';
import { useRefresh } from '../../general additions/RefreshProvider';
import { LoadingShopPart } from '../../general additions/LoadingElements';

export default function Details() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const { refreshKey } = useRefresh();

  const [quantity, setQuantity] = useState(1);
  const [activeSection, setActiveSection] = useState("details");
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);

  // Remove findDOMNode error :
  const originalError = console.error;
  console.error = (...args) => {
    if (/findDOMNode is deprecated/.test(args[0])) {
      return;  // Suppress the findDOMNode warning
    }
    originalError(...args);  // Keep all other errors in the console
  };

  useEffect(() => {
    axios.get(`${localhost}/productreview/${slug}`)
      .then((res) => {
        const productData = res.data[0];
        // Parse JSON strings
        productData.name = JSON.parse(productData.name);
        productData.details = JSON.parse(productData.details);
        productData.category = JSON.parse(productData.category);
        setProduct(productData);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [localhost, slug, refreshKey]);

  if (loading) return <div className='flex justify-center items-center min-h-[70vh]'><LoadingShopPart /></div>;

  return (
    <div className="py-12 antialiased">
      <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
        <div className="lg:grid lg:grid-cols-2 lg:gap-6 xl:gap-8 items-center">

          <div className="relative max-w-md mx-auto">
            <a href={"/product/" + product.slug}>
              <img className="w-full" src={product.image ? localhostImage + product.image : blankimage} alt={product.name[i18n.language]} />
              <span className={`absolute top-0 right-0 ${product.stock > 0 ? "bg-green-700" : "bg-red-700"} text-white text-sm px-3 py-1.5 rounded-bl-lg`}>
                {product.stock > 0 ? t("available") : t("unavailable")}
              </span>
            </a>
          </div>

          <div>
            <div className='flex flex-wrap items-center justify-center gap-2 w-52 text-gray-900 text-sm font-semibold ring-1 ring-gray-900 rounded-full px-2.5 py-1 mb-5'>
              <img src={officeIcon} className='w-5 h-auto' alt="" />
              {product.category[i18n.language]}
            </div>

            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl mb-5">{product.name[i18n.language] || product.name.en}</h1>

            <div className="mb-5 sm:items-center sm:flex">
              <div className="flex items-center gap-2 mt-2 sm:mt-0">
                <div className="flex items-center gap-1">
                  <RatingStars size={4} rating={product.ratings} />
                </div>
                <p className="text-sm font-medium leading-none text-gray-500">({product.ratings})</p>
                <a href="#_" className="text-sm font-medium leading-none text-gray-900 underline hover:no-underline">
                  {product.countreview} {t("reviews")}
                </a>
              </div>
            </div>

            <p className="text-2xl font-extrabold text-gray-900 mb-5 sm:text-3xl">${product.price}</p>

            <div>
              <ul className="max-w-md space-y-1 text-gray-700 text-md mb-5 list-inside">
                {
                  ["prolist-1", "prolist-2"].map((item, index) => (
                    <li key={index} className="flex items-center">
                      <CheckCircleIcon className="w-4 h-4 me-2 text-green-500 flex-shrink-0" />
                      {t(item)}
                    </li>
                  ))
                }
              </ul>
            </div>

            <div className="flex items-center">
              <button onClick={() => setQuantity(Math.max(1, quantity - 1))}
                className="flex justify-center items-center bg-gray-200 hover:bg-gray-300 rounded-md h-7 w-7">
                <MinusIcon className="w-5 h-5" />
              </button>

              <input type="text" value={quantity} onChange={(e) => setQuantity(Math.min(Math.max(1, parseInt(e.target.value) || 1), product.stock))}
                className="text-center w-14 border-0 ring-0 focus:ring-0" />

              <button onClick={() => setQuantity(prevQuantit => Math.min(product.stock, prevQuantit + 1))}
                className="flex justify-center items-center bg-gray-200 hover:bg-gray-300 rounded-md h-7 w-7" disabled={quantity >= product.stock}>
                <PlusIcon className="w-5 h-5" />
              </button>
            </div>

            <div className="sm:gap-4 sm:items-center sm:flex mt-16">
              <FavoriteBtn prodID={product.productID} />

              <AddCart prodID={product.productID} quantity={quantity} typeBtn="noModal" />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-10'>
        <div className='flex flex-wrap justify-center my-6 border-t border-blue-700 gap-2 items-center'>
          <button className={`px-3 py-2 border-b-4 ${activeSection === "details" ? "borderBar" : ""}`} onClick={() => setActiveSection("details")}>Description</button>
          <button className={`px-3 py-2 border-b-4 ${activeSection === "reviews" ? "borderBar" : ""}`} onClick={() => setActiveSection("reviews")}>{t("reviews")} ({product.countreview})</button>
        </div>
        {
          activeSection === "details" ? <RichTextEditor style={{ background: "transparent", border: "transparent" }} value={product.details[i18n.language] || product.details.en} readOnly />
            : <Reviews productId={product.productID} ratingProd={product.ratings} countRev={product.countreview} />
        }
      </div>
    </div>
  );
}
