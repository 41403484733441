import axios from 'axios';
import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import RatingStars from './RatingStars';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { officeIcon } from '../assets/icons';
import FavoriteBtn from './FavoriteBtn';
import { useRefresh } from '../general additions/RefreshProvider';
import { blankimage } from '../assets/images';

export default function AddCart({ prodID, prodSlug, quantity, typeBtn }) {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const token = localStorage.getItem("token");
  const [prod, setProd] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [quantit, setQuantit] = useState(1);
  const { triggerRefresh } = useRefresh();

  const fetchData = async () => {
    try {
      const res = await axios.get(`${localhost}/productreview/${prodSlug}`);
      const productData = res.data[0];
      productData.name = JSON.parse(productData.name);
      productData.details = JSON.parse(productData.details);
      productData.category = JSON.parse(productData.category);
      setProd(productData);
      setOpenModal(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddCart = () => {
    const orderData = { "quantity": typeBtn === "noModal" ? quantity : quantit, "status": "pending", "product_id": prodID };
    axios.post(localhost + "/orders", orderData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setTimeout(() => {
          triggerRefresh();
          setOpenModal(false);
        }, 500);
      }).catch((err) => console.error(err));
  };

  return (
    <>
      <button onClick={typeBtn === "noModal" ? handleAddCart : fetchData} className="text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] px-5 py-2.5 focus:outline-none flex items-center justify-center">
        <svg className="w-5 h-5 -ms-2 me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4h1.5L8 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.75-3H7.5M11 7H6.312M17 4v6m-3-3h6" />
        </svg>
        {t("addcart")}
      </button>

      {prod && (
        <Modal show={openModal} onClose={() => setOpenModal(false)}>
          <Modal.Header>{prod.name[i18n.language]}</Modal.Header>
          <Modal.Body>
            <div className="md:grid md:grid-cols-2 md:gap-6 xl:gap-8 items-center">
              <div className="relative max-w-md mx-auto">
                <a href={"/product/" + prod.slug}>
                  <img
                    className="w-full"
                    src={prod.image ? localhostImage + prod.image : blankimage}
                    alt={prod.name[i18n.language]}
                  />
                  <span className={`absolute top-0 right-0 ${prod.stock > 0 ? "bg-green-700" : "bg-red-700"} text-white text-xs px-2 py-1 rounded-bl-lg`}>
                    {prod.stock > 0 ? t("available") : t("unavailable")}
                  </span>
                </a>
              </div>

              <div className='max-md:mt-6'>
                <div className="flex items-center gap-2 justify-center text-gray-900 w-48 text-[12px] font-semibold ring-1 ring-gray-900 rounded-full px-2.5 py-1 mb-5">
                  <img src={officeIcon} className="w-[18px] h-auto" alt="" />
                  {prod.category[i18n.language]}
                </div>
                <a href={"/product/" + prod.slug} className="text-lg text-gray-900 font-semibold sm:text-xl mb-5 hover:underline">{prod.name[i18n.language] || prod.name.en}</a>
                <div className="flex items-center gap-2 mb-5">
                  <RatingStars size={4} rating={prod.ratings} />
                  <p className="text-sm font-medium text-gray-500">({prod.ratings})</p>
                  <p className="text-sm font-medium text-gray-900 underline hover:no-underline">
                    {prod.countreview} {t("reviews")}
                  </p>
                </div>
                <p className="text-2xl font-extrabold text-gray-900 mb-4">${prod.price}</p>


                <div className="flex items-center">
                  <button onClick={() => setQuantit(Math.max(1, quantit - 1))}
                    className="flex justify-center items-center bg-gray-200 hover:bg-gray-300 rounded-md h-7 w-7">
                    <MinusIcon className="w-5 h-5" />
                  </button>

                  <input type="text" value={quantit} onChange={(e) => setQuantit(Math.min(Math.max(1, parseInt(e.target.value) || 1), prod.stock))}
                    className="text-center w-14 border-0 ring-0 focus:ring-0" />

                  <button onClick={() => setQuantit(prevQuantit => Math.min(prod.stock, prevQuantit + 1))}
                    className="flex justify-center items-center bg-gray-200 hover:bg-gray-300 rounded-md h-7 w-7" disabled={quantit >= prod.stock}>
                    <PlusIcon className="w-5 h-5" />
                  </button>
                </div>


                <div className="flex flex-col gap-4 mt-4">
                  <button onClick={handleAddCart} className="text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] px-5 py-2.5 focus:outline-none flex items-center justify-center">
                    <svg className="w-5 h-5 -ms-2 me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4h1.5L8 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.75-3H7.5M11 7H6.312M17 4v6m-3-3h6" />
                    </svg>
                    {t("addcart")}
                  </button>
                  <FavoriteBtn prodID={prod.productID} />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
