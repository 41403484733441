import { EyeIcon } from "@heroicons/react/24/outline";
import RatingStars from "./RatingStars";
import i18n from 'i18next';
import AddCart from "./AddCart";
import FavoriteBtn from "./FavoriteBtn";
import { blankimage } from "../assets/images";
import { useTranslation } from "react-i18next";

export default function ProductCard({ data, typeProp }) {
  const { t } = useTranslation();
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const token = localStorage.getItem("token");

  // Check if data is available and ensure proper parsing
  if (!data) {
    return <p className="text-base text-gray-900">Loading...</p>;
  }

  let productName, productDetails, productCategory;

  try {
    if (typeProp === "notJson") {
      productName = data.name;
      productDetails = data.details;
      productCategory = data.category;
    } else {
      productName = JSON.parse(data.name);
      productDetails = JSON.parse(data.details);
      productCategory = JSON.parse(data.category);
    }
  } catch (error) {
    console.error("Error parsing JSON data: ", error);
    return <p className="text-base text-red-500">Error displaying product information</p>;
  }

  return (
    <div className="">
      <div className="relative max-w-md mx-auto">
        <a href={"/product/" + data.slug}>
          <img
            className="w-full"
            src={data.image ? localhostImage + data.image : blankimage}
            alt={data.name[i18n.language]}
          />
          <span className={`absolute top-0 right-0 ${data.stock > 0 ? "bg-green-700" : "bg-red-700"} text-white text-xs px-2 py-1 rounded-bl-lg`}>
            {data.stock > 0 ? t("available") : t("unavailable")}
          </span>
        </a>
      </div>

      <div className="pt-1">
        <div className="mb-1 flex items-center justify-between gap-4">
          <span className="me-2 rounded py-0.5 text-xs font-medium text-blue-800">Up to 35% off</span>

          <div className="flex items-center justify-end gap-1">
            {
              token ?
                (<>
                  <button className="rounded-lg p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900">
                    <span className="sr-only">Quick look</span>
                    <EyeIcon className="w-5 h-5" />
                  </button>

                  <FavoriteBtn prodID={data.productID} typeBtn="icon" />
                </>) : null
            }

          </div>
        </div>

        {/* Display the product name based on the selected language */}
        <a href={"/product/" + data.slug} className="text-lg font-semibold leading-tight text-gray-900 hover:underline">
          {productName[i18n.language]}
        </a>

        <div className="mt-2 flex items-center gap-2">
          <div className="flex items-center">
            <RatingStars rating={data.ratings} size={4} />
          </div>

          <p className="text-sm font-medium text-gray-900">{data.ratings}</p>
          <p className="text-sm font-medium text-gray-500">{typeof data.countreview === 'number'?`(${data.countreview})`:null}</p>
        </div>

        <div className="mt-4 flex items-center justify-between gap-4">
          <p className="text-xl font-extrabold leading-tight text-gray-900">${data.price}</p>

          <AddCart prodID={data.productID} prodSlug={data.slug} />
        </div>
      </div>
    </div>
  );
}
