import React from 'react';
import TopBar from '../ecrans/additions/TopBar';
import Header from '../ecrans/additions/Header';
import Footer from '../ecrans/additions/Footer';
import ProductsTable from './ProductsTable';
import AddBanner from './AddBanner';

export default function OwnDashboard() {
  return (
    <section className=''>
    <TopBar />
    <Header />
    <div className='bg-gray-50'>
     <AddBanner/>
      <ProductsTable/>
    </div>
    <Footer />
  </section>
  )
}
