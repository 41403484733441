import { XMarkIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useRefresh } from '../general additions/RefreshProvider';

export default function Dproduct({ prodSlug }) {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const { triggerRefresh } = useRefresh();

  const remove = () => {
    axios.delete(localhost + "/product/" + prodSlug, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        triggerRefresh();
      })
      .catch((err) => console.error(err));
  }

  const confirmRemove = () => {
    toast((toa) => (
      <div id="toast-interactive" className="w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow">
        <div className="flex">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-600 bg-red-200 rounded-lg">
            <TrashIcon className="w-5 h-5" />
            <span className="sr-only">delete icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">
            <span className="mb-1 text-sm font-semibold text-gray-900">{t("removeprod")}</span>
            <div className="mb-2 text-sm font-normal">{t("removeprod-1")}</div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <button onClick={() => {
                  remove();
                  toast.dismiss(toa.id);
                }} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300">{t("yes")}</button>
              </div>
              <div>
                <button onClick={() => toast.dismiss(toa.id)} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">{t("cancel")}</button>
              </div>
            </div>
          </div>
          <button onClick={() => toast.dismiss(toa.id)} className="h-6 w-6 ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 hover:bg-gray-100 inline-flex">
            <span className="sr-only">Close</span>
            <XMarkIcon className="w-4 h-4" />
          </button>
        </div>
      </div>),
      {
        style: { background: 'none', boxShadow: 'none' },
        duration: 5000,
        position: 'top-center',
      });
  }

  return (
    <button onClick={confirmRemove} className='flex justify-center items-center h-9 w-9 bg-red-700 hover:bg-red-800 text-white rounded-lg p-1.5'>
    <TrashIcon className='h-5 w-5' />
  </button>
  )
}
