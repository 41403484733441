import { Modal } from 'flowbite-react'
import React, { useState } from 'react'
import { StarIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useRefresh } from '../../general additions/RefreshProvider';

export default function ComentModel({ productID }) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [hover, setHover] = useState(0);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const localhost = process.env.REACT_APP_LOCALHOST;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const { triggerRefresh } = useRefresh();

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const reviewData = { "rating": rating, "comment": comment, "user_id": parseInt(userId), "product_id": productID };
      axios.post(`${localhost}/review`, reviewData, { headers: { 'Authorization': `Bearer ${token}` } })
        .then((res) => {
          triggerRefresh();
          setOpenModal(false);
          setRating(0);
          setComment("");
        }).catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <button onClick={() => setOpenModal(true)} className="rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300">
        {t("writereview")}
      </button>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>{t("addreview")}</Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} className="">
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <div className="flex flex-wrap items-center">
                  {[...Array(5)].map((star, index) => {
                    const ratingValue = index + 1;

                    return (
                      <label key={index}>
                        <input type="radio" name="rating" value={ratingValue} onClick={() => setRating(ratingValue)} style={{ display: "none" }} />
                        {ratingValue <= (hover || rating) ? (
                          <StarIcon className="w-5 h-5 text-yellow-300 cursor-pointer" onMouseEnter={() => setHover(ratingValue)} onMouseLeave={() => setHover(0)} />
                        ) : (
                          <StarIcon className="w-5 h-5 text-gray-400 cursor-pointer" onMouseEnter={() => setHover(ratingValue)} onMouseLeave={() => setHover(0)} />
                        )}
                      </label>
                    );
                  })}
                  <span className="ms-2 text-base font-bold text-gray-900 pt-1">{rating}.0 {t("outof")} 5</span>
                </div>
              </div>
              <div className="col-span-2">
                <label htmlFor="description" className="mb-2 block text-sm font-medium text-gray-900">{t("review-desc")}</label>
                <textarea value={comment} onChange={(e) => setComment(e.target.value)} id="description" rows="6" className="mb-2 block w-full rounded-lg border border-gray-300 bg-gray-100 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500" required=""></textarea>
              </div>

            </div>
            <div className="flex flex-wrap gap-4 items-center border-t border-gray-200 pt-4 md:pt-5">
              <button type="submit" className="me-2 inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300">{t("addreview")}</button>
              <button type="button" onClick={() => setOpenModal(false)} className="me-2 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100">{t("cancel")}</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
