import React, { useMemo, useState } from 'react';
import Footer from '../additions/Footer';
import TopBar from '../additions/TopBar';
import Header from '../additions/Header';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default function CheckoutForm() {
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = country => {
    setCountry(country)
  };

  return (
    <section className=''>
      <TopBar />
      <Header />
      <div className='bg-gray-50'>
        <div className="py-8 antialiased md:py-16">
          <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
            <div className="mx-auto max-w-5xl">
              <h2 className="text-xl font-semibold text-gray-900 sm:text-2xl">Payment</h2>

              <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12">
                <form action="#" className="w-full p-4 sm:p-6 lg:max-w-xl lg:p-8">

                  <div className='mb-6 grid grid-cols-2 gap-4'>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-gray-900">First name</label>
                      <input type="text" className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Joe" />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-gray-900">Last name</label>
                      <input type="text" className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Doe" />
                    </div>
                  </div>

                  <div className='mb-6 grid grid-cols-2 gap-4'>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                      <input type="email" className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Joe.doe@email.com" />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-gray-900">Phone</label>
                      {/* <input type="tel" className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="xxx-xxxx-xxx" /> */}
                      <PhoneInput className='bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1.5' placeholder="Enter phone number" value={phone} onChange={setPhone}/>
                    </div>
                  </div>

                  <div className='mb-6 grid grid-cols-2 gap-4'>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-gray-900">Country</label>
                      {/* <input type="text" className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="xxxxx" /> */}
                      
                      <Select className='focus:ring-0 outline-none ring-0' options={options} value={country} onChange={changeHandler} />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-gray-900">Postcode</label>
                      <input type="text" className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="xxxxx" />
                    </div>
                  </div>

                  <div className="mb-6 grid grid-cols-2 gap-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm sm:p-6">
                    <div className="col-span-2 sm:col-span-1">
                      <label className="mb-2 block text-sm font-medium text-gray-900"> Full name </label>
                      <input type="text" id="full_name" className="block w-full rounded-lg myborder bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500" placeholder="Bonnie Green" required />
                    </div>

                    <div className="col-span-2 sm:col-span-1">
                      <label className="mb-2 block text-sm font-medium text-gray-900"> Card number* </label>
                      <input type="text" id="card-number-input" className="block w-full rounded-lg myborder bg-gray-50 p-2.5 pe-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 " placeholder="xxxx-xxxx-xxxx-xxxx" pattern="^4[0-9]{12}(?:[0-9]{3})?$" required />
                    </div>

                    <div>
                      <label className="mb-2 block text-sm font-medium text-gray-900">Card expiration* </label>
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
                          <svg className="h-4 w-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path
                              fill-rule="evenodd"
                              d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        <input type="text" className="block w-full rounded-lg myborder bg-gray-50 p-2.5 ps-9 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500" placeholder="12/23" required />
                      </div>
                    </div>
                    <div>
                      <label className="mb-2 flex items-center gap-1 text-sm font-medium text-gray-900">
                        CVV*
                        <button className="text-gray-400 hover:text-gray-900">
                          <svg className="h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z" clip-rule="evenodd" />
                          </svg>
                        </button>
                        <div className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 ">
                          The last 3 digits on back of card
                        </div>
                      </label>
                      <input type="number" className="block w-full rounded-lg myborder bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500" placeholder="•••" required />
                    </div>
                  </div>

                  <button type="submit" className="flex w-full items-center justify-center rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4  focus:ring-blue-300">Pay now</button>
                </form>

                <div className="mt-6 grow sm:mt-8 lg:mt-0">
                  <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6 ">
                    <div className="space-y-2">
                      <dl className="flex items-center justify-between gap-4">
                        <dt className="text-base font-normal text-gray-500">Original price</dt>
                        <dd className="text-base font-medium text-gray-900">$6,592.00</dd>
                      </dl>

                      <dl className="flex items-center justify-between gap-4">
                        <dt className="text-base font-normal text-gray-500">Store Pickup</dt>
                        <dd className="text-base font-medium text-gray-900">$99</dd>
                      </dl>

                    </div>

                    <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                      <dt className="text-base font-bold text-gray-900">Total</dt>
                      <dd className="text-base font-bold text-gray-900">$7,191.00</dd>
                    </dl>
                  </div>

                  <div className="mt-6 flex items-center justify-center gap-8">
                    <img className="h-8 w-auto" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg" alt="" />
                    <img className="h-8 w-auto" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg" alt="" />
                    <img className="h-8 w-auto" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg" alt="" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      <Footer />
    </section>
  )
}
