import { Drawer } from "flowbite-react";
import { useEffect, useState } from "react";
import { ShoppingCartIcon } from "@heroicons/react/24/solid";
import { CartIon } from "../assets/icons";
import axios from "axios";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import RemoveOrder from "./RemoveOrder";
import { FormatDateForcomment } from "./FormatDate";
import { LoadingShopPart } from "../general additions/LoadingElements";
import { useRefresh } from "../general additions/RefreshProvider";

export default function ShoppingCart() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const uniqueID = localStorage.getItem("uniqueID");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { refreshKey } = useRefresh();

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    axios.get(localhost + "/orders/" + uniqueID, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setOrders(res.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [refresh, refreshKey]);

  const handleRefresh = () => {
    setRefresh(prev => !prev);
  };

  if (!orders) {
    return <p className="text-base text-gray-900">Loading...</p>;
  }

  // Subtotal calculation based on individual product prices and quantities
  const calculateSubtotal = () => {
    return orders.reduce((total, item) => total + parseFloat(item.total_amount), 0).toFixed(2);
  };

  return (
    <>
      <button onClick={()=>setIsOpen(true)} className="header-action-btn">
        <img src={CartIon} className='h-7 w-7 max-md:w-6 max-md:h-6 mx-auto' alt="" />
        <p className="header-action-label">{t("cart")}</p>
        <div className="btn-badge bg-blue-600">{orders.length}</div>
      </button>

      <Drawer className="w-1/3 max-xl:w-[40%] max-md:w-full" open={isOpen} onClose={handleClose} position="right">
        <Drawer.Header title={t("shop-cart")} titleIcon={() => <ShoppingCartIcon className="h-5 w-5 mr-1" />} />
        <Drawer.Items>
          {
            loading ?
              (<div className="flex justify-center items-center w-full min-h-[82vh]">
                <LoadingShopPart />
              </div>)
              : (<ul className='border-y border-gray-200 overflow-y-auto h-[82vh]'>
                {orders.map((item, index) => {
                  let productName, productDetails, productCategory;

                  try {
                    productName = JSON.parse(item.name);
                    productDetails = JSON.parse(item.details);
                    productCategory = JSON.parse(item.category);
                  } catch (error) {
                    console.error("Error parsing JSON data: ", error);
                    return <p className="text-base text-red-500">Error displaying product information</p>;
                  }

                  return (
                    <li key={index} className='flex flex-row items-center gap-3 px-3 py-4 border-b border-gray-200'>
                      <img className='w-auto h-24' src={localhostImage + item.image} alt={"product " + index} />
                      <div className='w-full'>
                        <h2 className="capitalize text-lg text-gray-900 font-semibold">{productName[i18n.language]}</h2>
                        <p className="text-xs"><FormatDateForcomment dateString={item.updated_at} /></p>

                        <div className='flex items-center justify-between mt-2'>
                          <p className='text-sm mt-1 flex flex-wrap font-semibold'>
                            <span className='text-gray-400 mr-1 font-normal'>{item.total_quantity} x</span> {parseFloat(item.price).toFixed(2)} $
                          </p>
                          <RemoveOrder prod_id={item.productID} onRemove={handleRefresh} />
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>)
          }

          <div className='p-2'>
            <h2 className='mt-2 font-semibold text-gray-900'>{t("subtotal")}: ${calculateSubtotal()}</h2>
            <button className='bg-blue-600 text-white hover:bg-blue-800 rounded-md w-full py-1.5 text-center'>{t("checkout")}</button>
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
