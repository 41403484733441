import { bundlesIcon, microsoftIcon, officeIcon, securityIcon, serverIcon, windowsIcon } from "../icons";

export const categories = [
  {
    name: "Microsoft-office",
    icon: officeIcon
  },
  {
    name: "Office-products",
    icon: microsoftIcon
  },
  {
    name: "Operating-system",
    icon: windowsIcon
  },
  {
    name: "Windows-servers",
    icon: serverIcon
  },
  {
    name: "Antivirus-security",
    icon: securityIcon
  },
  {
    name: "Bundles",
    icon: bundlesIcon
  },
];