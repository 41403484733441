import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function AddBanner() {
  const { t } = useTranslation();

  return (
    <section className="container pt-6">
      <div className="mx-auto max-w-7xl">
        <div className="lg:py-8 lg:px-20 p-10 rounded-xl bg-gradient-to-r from-blue-900 to-blue-700 flex items-center justify-between flex-col lg:flex-row">
          <div className="block text-center lg:text-left">
            <h2 className="text-2xl text-white font-semibold">
              {t("titleBanner")}
            </h2>
            <p className="text-base text-indigo-100">{t("textBanner")}</p>
          </div>
          <a href='/admin/add-product' className="bg-white rounded-full shadow-sm text-lg p-1.5 text-blue-500 transition-all duration-500">
            <PlusIcon className="h-8 w-8 text-blue-500" />
          </a>
        </div>
      </div>
    </section>
  )
}
