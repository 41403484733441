import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { FormatDate } from '../elements/FormatDate';
import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import ImageModal from './ImageModal';
import Dproduct from './Dproduct';
import { useRefresh } from '../general additions/RefreshProvider';

export default function ProductsTable() {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const { refreshKey } = useRefresh();

  useEffect(() => {
    axios.get(`${localhost}/products`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    }).then((res) => {
      setProducts(res.data);
    }).catch((err) => console.error(err))
  }, [refreshKey]);

  return (
    <div className="container relative px-16 pb-16 overflow-x-auto sm:rounded-lg">
      <h1 className='text-3xl text-center py-8 text-gray-900'>{t("welcome-table")}</h1>
      <table className="w-full shadow-lg text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              {t("product")} ID
            </th>
            <th scope="col" className="px-6 py-3">
              Image
            </th>
            <th scope="col" className="px-6 py-3">
              {t("name")}
            </th>
            <th scope="col" className="px-6 py-3">
              {t("price")}
            </th>
            <th scope="col" className="px-6 py-3">
              {t("category")}
            </th>
            <th scope="col" className="px-6 py-3">
              {t("ratings")}
            </th>
            <th scope="col" className="px-6 py-3">
              {t("dateaddition")}
            </th>
            <th scope="col" className="px-2 py-3">
              {t("view")}
            </th>
            <th scope="col" className="px-2 py-3">
              {t("edit")}
            </th>
            <th scope="col" className="px-2 py-3">
              {t("delete")}
            </th>
          </tr>
        </thead>
        <tbody>
          {
            products.map((item, index) => (
              <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                <td className="px-6 py-4">
                  {item.uniqueProd}
                </td>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  <ImageModal productImg={item.image} productName={item.name[i18n.language]} productSlug={item.slug} />
                </th>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  <p className="font-medium">{item.name[i18n.language]}</p>
                </th>
                <td className="px-6 py-4">
                  {item.price}
                </td>
                <td className="px-6 py-4">
                  {item.category[i18n.language]}
                </td>
                <td className="px-6 py-4">
                  {item.ratings}
                </td>
                <td className="px-6 py-4">
                  <FormatDate dateString={item.created_at} />
                </td>
                <td className="px-2 py-4">
                  <a href={`/product/${item.slug}`} className='flex justify-center items-center h-9 w-9 bg-blue-700 hover:bg-blue-800 text-white rounded-lg p-1.5'>
                    <EyeIcon className='h-5 w-5' />
                  </a>
                </td>
                <td className="px-2 py-4">
                  <a href={`/admin/update-product/${item.slug}`} className='flex justify-center items-center h-9 w-9 bg-green-700 hover:bg-green-800 text-white rounded-lg p-1.5'>
                    <PencilSquareIcon className='h-5 w-5' />
                  </a>
                </td>
                <td className="px-2 py-4">
                  <Dproduct prodSlug={item.slug} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
