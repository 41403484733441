import { Route, Routes } from "react-router";
import "./assets/styles.css";
import "./assets/font/font.css";

import Home from "./ecrans/home/Home";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Shop from "./ecrans/shop/Shop";
import ContactUs from "./ecrans/about & contact/ContactUs";
import AboutUs from "./ecrans/about & contact/AboutUs";
import DetailsProduct from "./ecrans/details product/DetailsProduct";
import Order from "./ecrans/order/Order";
import Settings from "./ecrans/dashboard/Settings";
import CheckoutForm from "./ecrans/order/CheckoutForm";
import OwnDashboard from "./admin/OwnDashboard";
import Uproduct from "./admin/Uproduct";
import Cproduct from "./admin/Cproduct";
import { RefreshProvider } from "./general additions/RefreshProvider";
import MyFavorites from "./ecrans/myfavorites/MyFavorites";

function App() {
  return (
    <RefreshProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />

        <Route path="/product/:slug" element={<DetailsProduct />} />
        <Route path="/order" element={<Order />} />
        <Route path="/order/checkout" element={<CheckoutForm />} />

        <Route path="/account" element={<Settings />} />
        <Route path="/favorites" element={<MyFavorites />} />

        {/* Admin : */}
        <Route path="/admin/dashboard" element={<OwnDashboard />} />

        <Route path="/admin/add-product" element={<Cproduct />} />
        <Route path="/admin/update-product/:slug" element={<Uproduct />} />
      </Routes>
    </RefreshProvider>
  );
}

export default App;
