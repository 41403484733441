import { Carousel } from 'flowbite-react';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { useTranslation } from 'react-i18next';

export default function Testimonials() {
  const { t } = useTranslation();

  return (
    <section className="container py-10">
      <div>
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-px my-5 bg-gray-300 border-0" />
            <span className="text-lg max-md:text-base font-semibold absolute px-3 text-gray-900 -translate-x-1/2 bg-white left-1/2">{t("testimonials")}</span>
          </div>
          <p className="text-sm font-normal text-gray-500 max-w-md md:max-w-2xl mx-auto">
            {t("testimonials-1")}
          </p>
        </div>

        <div className='flex flex-wrap items-center justify-center gap-4 my-8'>
          <div className='flex flex-wrap'>
            {
              [1, 2, 3, 4, 5].map((index) => (
                <StarIcon key={index} className={`h-5 w-5 pb-1 ${index > 4 ? "text-gray-500" : "text-amber-500"}`} />
              ))
            }
          </div>
          <p className="text-base max-md:text-sm flex flex-wrap gap-4 font-normal text-gray-900">
            <span className='font-semibold'>4</span> 403 reviews
          </p>
        </div>

        <div className="h-60 max-md:h-[700px]">
          <Carousel
            leftControl={<CustomPrevButton />}
            rightControl={<CustomNextButton />}
          >
            {
              [1, 2, 3, 4, 5].map((item, index) => (
                <div key={index} className="flex flex-row max-md:flex-col gap-2 p-2 h-full items-center justify-center bg-transparent">
                  {
                    [1, 2, 3].map((item, index) => (
                      <div key={index} className="swiper-slide group bg-white border border-solid border-gray-300 rounded-2xl p-6 transition-all duration-500 w-full hover:border-blue-600 ">
                        <div className="flex items-center mb-2 gap-2 text-amber-500 transition-all duration-500  group-hover:text-blue-600 swiper-slide-active:text-blue-600">
                          {
                            [1, 2, 3, 4, 5].map((index) => (
                              <svg key={index} className="w-5 h-5" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                  fill="currentColor" />
                              </svg>
                            ))
                          }

                        </div>
                        <p className="text-sm text-gray-500 leading-8 min-h-16 transition-all duration-500 mb-4 group-hover:text-gray-800 ">
                          Pagedone stands out as the most user-friendly and effective solution I've ever used.
                        </p>
                        <div className="flex items-center gap-5">
                          <img className="rounded-full object-cover" src="https://pagedone.io/asset/uploads/1696230027.png"
                            alt="avatar" />
                          <div className="grid gap-1">
                            <h5
                              className="text-gray-900 font-medium transition-all duration-500 group-hover:text-blue-600 swiper-slide-active:text-blue-600">
                              Alex K.</h5>
                            <span className="text-sm leading-6 text-gray-500">Design Lead</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </Carousel>
        </div>
      </div>
    </section>
  );
};

// Buttons :
const CustomPrevButton = () => <ChevronLeftIcon className='h-8 w-8 text-gray-900' />;
const CustomNextButton = () => <ChevronRightIcon className='h-8 w-8 text-gray-900' />;
