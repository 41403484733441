import React from 'react';
import TopBar from '../additions/TopBar';
import Header from '../additions/Header';
import Footer from '../additions/Footer';
import SideMenu from './SideMenu';

export default function Settings() {
  return (
    <section className=''>
    <TopBar />
    <Header />
    <div className='bg-gray-50'>
      <SideMenu/>
    </div>
    <Footer />
  </section>
  )
}
