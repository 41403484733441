import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { EyeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Modal } from 'flowbite-react';
import React, { useState } from 'react'

export default function ImageModal({ productImg, productName, productSlug }) {
  const [open, setOpen] = useState(false);
  const localimg = process.env.REACT_APP_LOCALHOST_IMG;

  return (
    <>
      <button onClick={() => setOpen(true)}>
        <PhotoIcon className="h-6 w-6 text-gray-900 cursor-pointer hover:text-blue-500" />
      </button>

      <Modal show={open} onClose={() => setOpen(false)} size="md">
        <Modal.Body className='pb-3 pl-3 pr-2 pt-2'>
          <div>
            <div className='flex justify-between items-center'>
              <h1 className='font-semibold text-gray-900 pb-1 text-center text-base'>{productName}</h1>
              <button onClick={() => setOpen(false)} className='p-0.5 hover:bg-gray-300 rounded-md'>
                <XMarkIcon className='h-5 w-5 text-gray-900' />
              </button>
            </div>
            <img src={localimg + "/" + productImg} alt="product" />

            <div className='flex flex-wrap justify-center items-center gap-2 mt-3'>
              <a href={`/product/${productSlug}`} className='flex justify-center items-center h-9 w-9 bg-blue-700 hover:bg-blue-800 text-white rounded-lg p-1.5'>
                <EyeIcon className='h-5 w-5' />
              </a>

              <a href={`/admin/update-product/${productSlug}`} className='flex justify-center items-center h-9 w-9 bg-green-700 hover:bg-green-800 text-white rounded-lg p-1.5'>
                <PencilSquareIcon className='h-5 w-5' />
              </a>

              <button className='flex justify-center items-center h-9 w-9 bg-red-700 hover:bg-red-800 text-white rounded-lg p-1.5'>
                <TrashIcon className='h-5 w-5' />
              </button>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}