import axios from "axios";
import { useState, useEffect } from "react";
import { ArrowUpTrayIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { bundlesIcon, microsoftIcon, officeIcon, securityIcon, serverIcon, windowsIcon } from "../assets/icons";
import { Dropdown } from "flowbite-react";
import { useNavigate, useParams } from "react-router";
import { RichTextEditor } from '@mantine/rte';
import TopBar from "../ecrans/additions/TopBar";
import Header from "../ecrans/additions/Header";
import Footer from "../ecrans/additions/Footer";
import { useTranslation } from "react-i18next";

export default function Uproduct() {
  const { slug } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const token = localStorage.getItem('token');
  const user_id = localStorage.getItem('userID');
  const [activeCateg, setActiveCateg] = useState("Microsoft office");

  const [image, setImage] = useState(null);
  const [nameEn, setNameEn] = useState("");
  const [nameFr, setNameFr] = useState("");
  const [price, setPrice] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionFr, setDescriptionFr] = useState("");

  // Remove findDOMNode error :
  const originalError = console.error;
  console.error = (...args) => {
    if (/findDOMNode is deprecated/.test(args[0])) {
      return;  // Suppress the findDOMNode warning
    }
    originalError(...args);  // Keep all other errors in the console
  };

  useEffect(() => {
    axios.get(`${localhost}/product/${slug}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    }).then((res) => {
      const data = res.data[0];
      data.name = JSON.parse(data.name);
      data.details = JSON.parse(data.details);
      data.category = JSON.parse(data.category);
      console.log(data);
      setActiveCateg(data.category.en)
      setNameEn(data.name.en);
      setNameFr(data.name.fr);
      setPrice(data.price);
      setDescriptionEn(data.details.en);
      setDescriptionFr(data.details.fr);
      setImage(data.image);
    })
      .catch((err) => console.error(err));
  }, [])

  const categories = [
    {
      titleEn: "Microsoft office",
      titleFr: "Microsoft office",
      slung: "Microsoft-office",
      icon: officeIcon
    },
    {
      titleEn: "Office products",
      titleFr: "Produits de office",
      slung: "Office-products",
      icon: microsoftIcon
    },
    {
      titleEn: "Operating system",
      titleFr: "Système d'exploitation",
      slung: "Operating-system",
      icon: windowsIcon
    },
    {
      titleEn: "Windows servers",
      titleFr: "Serveurs Windows",
      slung: "Windows-servers",
      icon: serverIcon
    },
    {
      titleEn: "Antivirus & security",
      titleFr: "Antivirus et sécurité",
      slung: "Antivirus-security",
      icon: securityIcon
    },
    {
      titleEn: "Bundles",
      titleFr: "Collection",
      slung: "Bundles",
      icon: bundlesIcon
    },
  ];
  const formats = [
    ['bold', 'italic', 'underline', 'link', 'image'],
    ['unorderedList', 'h1', 'h2', 'h3'],
    ['sup', 'sub'],
    ['alignLeft', 'alignCenter', 'alignRight'],
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const productData = {
      name: {
        en: nameEn,
        fr: nameFr
      },
      details: {
        en: descriptionEn,
        fr: descriptionFr
      },
      price: parseFloat(price),
      category: {
        en: activeCateg,
        fr: categories.find((res) => res.titleEn === activeCateg)?.titleFr
      },
      image: image
    };

    axios.post(`${localhost}/edit/${slug}`, productData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    })
      .then((res) => {
        // setTimeout(() => { navigate("/products") }, 2000);
        console.log(res.data);
      })
      .catch((err) => console.error(err));
  }

  return (
    <section className=''>
      <TopBar />
      <Header />
      <div className="bg-gray-50 py-12 antialiased">
        <form onSubmit={handleSubmit} className="container px-4 mx-auto 2xl:px-0">
          <div className="lg:grid lg:grid-cols-2 lg:gap-6 xl:gap-8 ">
            <label className="cursor-pointer" htmlFor="productImage">
              <div className="flex justify-center items-center shrink-0 px-12 max-w-[448px] max-h-[480px] mx-auto">
                {image ? (
                  <img src={typeof image === "object" ? URL.createObjectURL(image) : localhostImage + image} alt="Uploaded Product" className="max-w-full max-h-full object-contain" />
                ) : (
                  <div className="flex flex-col justify-center h-60 items-center">
                    <ArrowUpTrayIcon className="text-gray-900 h-20 w-auto" />
                    <p className="mt-3 text-gray-900 text-center text-xs sm:text-sm">
                      <strong>{t("upload-image1")}</strong> <br />{t("upload-image2")}
                    </p>
                  </div>
                )}
                <input id="productImage" type="file" onChange={(e) => setImage(e.target.files[0])} className="hidden" accept="image/jpeg, image/jpg, image/png" required />
              </div>
            </label>

            <div className="max-md:mt-6">
              <div className="max-w-md mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900">{t("category")}</label>
                <Dropdown label="" dismissOnClick={false} renderTrigger={() => (
                  <div className='flex flex-wrap items-center justify-center gap-2 cursor-pointer w-52 text-gray-900 text-sm font-semibold ring-1 ring-gray-900 rounded-full px-2.5 py-2 mb-5'>
                    <img src={categories.find((res) => res.titleEn === activeCateg)?.icon} className='w-5 h-5' alt={activeCateg} />
                    {t(categories.find((res)=>res.titleEn === activeCateg)?.slung)}
                  </div>
                )}>
                  {
                    categories.map((item, index) => (
                      <Dropdown.Item key={index} onClick={() => setActiveCateg(item.titleEn)}>
                        <div className="flex flex-wrap gap-1">
                          <img src={item.icon} className="w-5 h-5" alt={item.titleEn} />{t(item.slung)}
                        </div>
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown>
              </div>

              <div className="max-w-md mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900">Product name <strong className="text-blue-700">(en)</strong></label>
                <input type="text" value={nameEn} onChange={(e) => setNameEn(e.target.value)} className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder="Here write the product name..." />
              </div>
              <div className="max-w-md mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900">Nom du produit <strong className="text-blue-700">(fr)</strong></label>
                <input type="text" value={nameFr} onChange={(e) => setNameFr(e.target.value)} className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder="Écrivez ici le nom du produit..." />
              </div>
              <div className="max-w-md mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900">Price <strong className="text-blue-700">($)</strong></label>
                <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-full p-2.5" placeholder="10.99" />
              </div>
            </div>
          </div>

          {/* Description : */}
          <div className='mt-10'>
            <div className='flex flex-wrap justify-center my-6 border-t border-blue-700 gap-2 items-center'>
              <button className="px-3 py-2 border-b-4 borderBar">Description</button>
            </div>

            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">Description <strong className="text-blue-700">(en)</strong></label>
              <RichTextEditor style={{ minHeight: "250px" }} value={descriptionEn} onChange={setDescriptionEn} controls={formats} />
            </div>

            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">Description <strong className="text-blue-700">(fr)</strong></label>
              <RichTextEditor style={{ minHeight: "250px" }} value={descriptionFr} onChange={setDescriptionFr} controls={formats} />
            </div>
          </div>

          <button onClick={handleSubmit} className="text-white mt-5 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none flex items-center justify-center">
            <PencilSquareIcon className="w-5 h-5 -ms-2 me-2" />
            {t("edit-prod")}
          </button>
        </form>
      </div>
      <Footer />
    </section>
  )
};
