import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../auth/getUserInfo";

export default function AccountDetails() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const [error, setError] = useState({
    username: '',
    password: ''
  });

  const [ref, setRef] = useState(false);
  const [usernameActive, setUsernameActive] = useState(false);
  const [nameActive, setNameActive] = useState(false);
  const [emailActive, setEmailActive] = useState(false);
  const [pswActive, setPswActive] = useState(false);

  const [username, setUsername] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await getUserInfo(); // Using the getUserInfo function
      if (data) {
        console.log(data);
        setUsername(data.username);
        setFname(data.fname ? data.fname : "");
        setLname(data.lname ? data.lname : "");
        setEmail(data.email);
      }
    };
    fetchUserData();
  }, [ref]);

  const updateUsername = (e) => {
    e.preventDefault();
    axios.put(localhost + "/update-username", { username }, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data);
        setError({ username: '' });
        setRef(!ref);
        setUsernameActive(false);
      }).catch(() => setError({ username: 'error-username' }));
  };

  const updateName = (e) => {
    e.preventDefault();
    axios.put(localhost + "/update-name", { fname, lname }, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data);
        setRef(!ref);
        setNameActive(false);
      }).catch((err) => console.error(err));
  };

  const updateEmail = (e) => {
    e.preventDefault();
    axios.put(localhost + "/update-email", { email }, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data);
        setRef(!ref);
        setEmailActive(false);
      }).catch((err) => console.error(err));
  };

  const updatePassword = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError({ password: 'error-not-match' });
    } else {
      setError({ password: '' });
      axios.put(localhost + "/update-password", { 'current_password': currentPassword, 'new_password': newPassword, "new_password_confirmation": confirmPassword }, { headers: { 'Authorization': `Bearer ${token}` } })
        .then((res) => {
          console.log(res.data);
          setPswActive(false);
        }).catch((err) => console.error(err));
    }
  };

  return (
    <div className="col-span-8 pt-6 pb-12 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
      <div className="pt-4">
        <h1 className="py-2 text-2xl text-gray-900 font-semibold">{t("account-details")}</h1>
        <p className="font- text-slate-600">{(t("leave-blank"))}</p>
      </div>
      <hr className="mt-4 mb-8" />
      <p className="py-2 text-xl text-gray-900 font-semibold">{t("useranme")}</p>
      {
        usernameActive === false ?
          (<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <p className="text-gray-600">{t("edit-username")} <strong className='underline '>{username}</strong></p>
            <button type="button" onClick={() => setUsernameActive(true)} className="inline-flex text-sm font-semibold text-blue-600 underline decoration-2">{t("edit")}</button>
          </div>)
          : (<form onSubmit={updateUsername} className="mb-5">
            <div className="flex flex-wrap gap-1">
              <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" className={`${error.username ? "error-border focus:ring-red-700 focus:border-red-700" : "focus:ring-blue-500 focus:border-blue-500"} max-w-sm bg-gray-50 myborder text-gray-900 text-sm rounded-lg block w-full p-2.5`} placeholder="john.doe" />

              <button type="submit" className="text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">{t("save")}</button>
              <button type="button" onClick={() => setUsernameActive(false)} className="text-gray-900 mt-4 sm:mt-0 bg-gray-50 hover:bg-gray-200 myborder focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">{t("cancel")}</button>
            </div>
            {error.username && <p className="text-sm text-red-700">{t(error.username)}</p>}
          </form>)
      }

      <hr className="mt-4 mb-8" />
      <p className="py-2 text-xl text-gray-900 font-semibold">{t("fullname")}</p>
      {
        nameActive === false ?
          (<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            {
              fname && lname ?
                <p className="text-gray-600">{t("edit-name")} <strong className='underline '>{fname} {lname}</strong></p>
                : <p className="text-gray-600">{t("new-name")}</p>
            }
            <button type="button" onClick={() => setNameActive(true)} className="inline-flex text-sm font-semibold text-blue-600 underline decoration-2">{t("edit")}</button>
          </div>)
          : (<form onSubmit={updateName}>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-1 mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900">{t("fname")}</label>
                <input value={fname} onChange={(e) => setFname(e.target.value)} type="text" className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Joe" />
              </div>
              <div className="col-span-1 mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900">{t("lname")}</label>
                <input value={lname} onChange={(e) => setLname(e.target.value)} type="text" className="bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Doe" />
              </div>
            </div>
            <div className='flex flex-wrap gap-1'>
              <button type="submit" className="text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">{t("save")}</button>
              <button type="button" onClick={() => setNameActive(false)} className="text-gray-900 mt-4 sm:mt-0 bg-gray-50 hover:bg-gray-200 myborder focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">{t("cancel")}</button>
            </div>
          </form>)
      }

      <hr className="mt-4 mb-8" />
      <p className="py-2 text-xl text-gray-900 font-semibold">{t("edit-email")}</p>
      {
        emailActive === false ?
          (<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <p className="text-gray-600">{t("edit-email-1")} <strong className='underline '>{email}</strong></p>
            <button type="button" onClick={() => setEmailActive(true)} className="inline-flex text-sm font-semibold text-blue-600 underline decoration-2">{t("edit")}</button>
          </div>)
          : (<form onSubmit={updateEmail} className="flex flex-wrap gap-1 mb-5">
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="max-w-sm bg-gray-50 myborder text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@company.com" />

            <button type="submit" className="text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">{t("save")}</button>
            <button type="button" onClick={() => setEmailActive(false)} className="text-gray-900 mt-4 sm:mt-0 bg-gray-50 hover:bg-gray-200 myborder focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">{t("cancel")}</button>
          </form>)
      }


      <hr className="mt-4 mb-8" />
      <p className="py-2 text-xl text-gray-900 font-semibold">{t("edit-password")}</p>
      {
        pswActive === false ?
          (<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <p className="text-gray-600">{t("edit-password-1")} <strong className='underline'>{t("edit-password-2")}</strong>?</p>
            <button type="button" onClick={() => setPswActive(true)} className="inline-flex text-sm font-semibold text-blue-600 underline decoration-2">{t("edit")}</button>
          </div>)
          : (<form onSubmit={updatePassword} className="max-w-sm">
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">{t("current-password")}</label>
              <input value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type="password" className={`${error.password ? "error-border focus:ring-red-700 focus:border-red-700" : "focus:ring-blue-500 focus:border-blue-500"} bg-gray-50 myborder text-gray-900 text-sm rounded-lg block w-full p-2.5`} placeholder="********" />
            </div>
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">{t("new-password")}</label>
              <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" className={`${error.password ? "error-border focus:ring-red-700 focus:border-red-700" : "focus:ring-blue-500 focus:border-blue-500"} bg-gray-50 myborder text-gray-900 text-sm rounded-lg block w-full p-2.5`} placeholder="********" />
            </div>
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">{t("conf-new-password")}</label>
              <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" className={`${error.password ? "error-border focus:ring-red-700 focus:border-red-700" : "focus:ring-blue-500 focus:border-blue-500"} bg-gray-50 myborder text-gray-900 text-sm rounded-lg block w-full p-2.5`} placeholder="********" />
              {error.password && <p className="text-sm text-red-700">{t(error.password)}</p>}
            </div>

            <div className='flex flex-wrap gap-1'>
              <button type="submit" className="text-white mt-4 sm:mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">{t("save")}</button>
              <button type="button" onClick={() => setPswActive(false)} className="text-gray-900 mt-4 sm:mt-0 bg-gray-50 hover:bg-gray-200 myborder focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">{t("cancel")}</button>
            </div>
          </form>)
      }

    </div>
  )
}
