import React, { useEffect, useState } from 'react';
import { bundlesIcon, laptopIcon, microsoftIcon, officeIcon, securityIcon, serverIcon, windowsIcon } from '../../assets/icons';
import { Dropdown } from 'flowbite-react';
import FilterBar from './FilterBar';
import Products from './Products';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LoadingShopPart } from '../../general additions/LoadingElements';

export const categories = [
  { title: "All-products", icon: laptopIcon },
  { title: "Microsoft-office", icon: officeIcon },
  { title: "Office-products", icon: microsoftIcon },
  { title: "Operating-system", icon: windowsIcon },
  { title: "Windows-servers", icon: serverIcon },
  { title: "Antivirus-security", icon: securityIcon },
  { title: "Bundles", icon: bundlesIcon },
];

export default function CategoriesBar() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All-products');

  useEffect(() => {
    axios.get(localhost + "/productlist")
      .then((res) => {
        setProducts(res.data);
        setFilteredProducts(res.data);
        setLoading(false);
      }).catch((err) => console.error(err));
  }, [localhost]);

  // Function to filter products by category
  const filterByCategory = (category) => {
    setSelectedCategory(category);
    if (category === 'All-products') {
      setFilteredProducts(products); // Show all products
    } else {
      const filtered = products.filter(product => {
        const productCategory = JSON.parse(product.category).en; // Assuming categories are stored in 'en'
        return productCategory === t(category, { lng: 'en' });
      });
      setFilteredProducts(filtered);
    }
  };

  return (
    <div className='container pb-6'>
      <div className="items-end justify-between border-b pb-5 space-y-4 sm:flex sm:space-y-0">
        <div>
          <h2 className="mt-3 text-xl font-semibold text-gray-900 sm:text-2xl">{t("our-products")}</h2>
        </div>
        <div className="flex items-center space-x-4">
          <Dropdown label="" dismissOnClick={false} renderTrigger={() => (
            <button className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 sm:w-auto">
              <svg className="-ms-0.5 me-2 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 4v16M7 4l3 3M7 4 4 7m9-3h6l-6 6h6m-6.5 10 3.5-7 3.5 7M14 18h4" />
              </svg>
              {t("sort")}
              <svg className="-me-0.5 ms-2 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 9-7 7-7-7" />
              </svg>
            </button>
          )}>
            <Dropdown.Item>{t("popular")}</Dropdown.Item>
            <Dropdown.Item>{t("newest")}</Dropdown.Item>
            <Dropdown.Item>{t("inc-price")}</Dropdown.Item>
            <Dropdown.Item>{t("dec-price")}</Dropdown.Item>
          </Dropdown>

          <FilterBar onCategorySelect={filterByCategory} />
        </div>
      </div>

      <div className='grid grid-cols-5 grid-flow-col gap-3'>
        <div className='flex flex-col col-span-1 gap-5 max-md:hidden'>
          <h2 className="mt-3 text-lg font-semibold text-gray-900">{t("product-categories")}</h2>
          {categories.map((item, index) => (
            <button
              key={index}
              onClick={() => filterByCategory(item.title)}
              className={`flex flex-wrap items-center gap-2 py-3 text-gray-900 text-sm font-semibold ${selectedCategory === item.title ? 'bg-gray-100' : ''}`}>
              <img src={item.icon} className='w-6 h-auto' alt="" />
              {t(item.title)}
            </button>
          ))}
        </div>

        {
          loading ?
            (<div className='flex justify-center items-center col-span-4 max-md:col-span-5'>
              <LoadingShopPart />
            </div>)
            : <Products allData={filteredProducts} />
        }
      </div>
    </div>
  );
}
