import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export default function OrderCard({ data }) {
  const { t } = useTranslation();
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;

  // Parse the name and category from JSON strings
  const parsedName = JSON.parse(data.name);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-[550px]:gap-6 py-3 border-b border-gray-200 max-sm:max-w-xl max-xl:mx-auto">
      <div className="flex items-center flex-col min-[550px]:flex-row gap-3 min-[550px]:gap-4 w-full max-sm:justify-center max-sm:max-w-xl max-xl:mx-auto">
        <div className="img-box bg-gray-50 rounded-xl justify-center items-center inline-flex">
          <img src={localhostImage + data.image} alt="product" className="md:w-24 object-cover" />
        </div>
        <div className="pro-data w-full max-w-sm flex-col justify-start items-start gap-2 inline-flex">
          <h4 className="w-full text-gray-900 text-lg font-medium leading-8 max-[550px]:text-center">
            {parsedName[i18n.language]}
          </h4>
          <h5 className="w-full text-gray-500 text-base font-normal leading-relaxed min-[550px]:my-0 my-2 max-[550px]:text-center">
            {t("product")} ID: {data.uniqueProd}
          </h5>
        </div>
      </div>

      <div className="w-full flex items-center justify-between flex-col min-[550px]:flex-row max-sm:max-w-xl max-xl:mx-auto gap-2">
        <h5 className=" w-full text-center text-gray-900 text-lg font-medium leading-relaxed">
          {t("quantity")} : {data.total_quantity}
        </h5>
        <h5 className=" w-full text-center text-gray-900 text-lg font-medium leading-relaxed">
          {t("price")} : ${data.price}
        </h5>
        <h5 className=" w-full text-center text-gray-900 text-lg font-medium leading-relaxed">
          Total : ${data.total_amount}
        </h5>
      </div>
    </div>
  );
}
