import React from 'react'

export default function WhyUs() {
  return (
    <section className="container">

      <div className='flex justify-between items-center max-lg:flex-col gap-10 w-full max-container'>
        <div className='flex flex-1 flex-col'>
          <h2 className='text-gray-900 font-semibold text-xl mb-3'>Why Choose Us</h2>
          <p className='text-sm text-gray-800'>
            Name's website - aims to simplify the software licensing process and provide customers of all needs, businesses and individuals a like., a comprehensive collection of software from major manufacturers, In our software shop, you can order general PC/MAC software, antivirus programs, Microsoft Office and servers with the corresponding license at excellent and affordable prices.
            <br /><br />
            We also know our products inside out as we are highly experienced technicians who can solve any software related issue in a quick timely manner simply contact our friendly technical support team anytime via chat or email.
          </p>
        </div>

        <div className='flex-1 flex justify-center items-center'>
          <img src="https://allgoodkeys.com/wp-content/uploads/2023/12/Untitled-design-2023-12-21T185757.680.png" alt='product detail' width={480} className='object-contain h-auto'/>
        </div>
      </div>

    </section>
  )
}
