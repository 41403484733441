import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";

export default function Register() {
  const navigate = useNavigate();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const signup = async (e) => {
    e.preventDefault();
    try {
      if (password1 === password2) {
        const userData = { "username": username, "email": email, "password": password1 };
        const signupResponse = await axios.post(`${localhost}/register`, userData);

        localStorage.setItem('token', signupResponse.data);
        axios.get(`${localhost}/user`, { headers: { 'Authorization': `Bearer ${signupResponse.data}` } })
          .then((res) => {
            localStorage.setItem('userID', res.data.userID);
            localStorage.setItem('username', res.data.username);
            localStorage.setItem('role', res.data.role);

            setTimeout(() => { navigate("/") }, 1500);
          }).catch((err) => console.error(err));
      } else {
        console.log("passwords are not the same!");
      }

    } catch (err) {
      console.error(err);
    }
  }

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#_" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
          Name's Website
        </a>
        <div className="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Create an account
            </h1>
            <form onSubmit={signup} className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">Username</label>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="joe.doe" required />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="name@sharedsystem.com" required />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                <input type="password" value={password1} onChange={(e) => setPassword1(e.target.value)} name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" required />
              </div>

              <div>
                <label htmlFor="password2" className="block mb-2 text-sm font-medium text-gray-900">Confirm password</label>
                <input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} name="password" id="password2" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" required />
              </div>

              <div>
                <div className="flex flex-wrap items-center">
                  <input type="checkbox" className="w-4 h-4 mr-2 border-2 border-gray-500 rounded bg-gray-200 focus:ring-3 focus:ring-blue-300" required />
                  <label htmlFor="terms" className="flex flex-wrap text-sm font-light text-gray-500">I accept the <a className="font-medium text-blue-600 hover:underline  ml-1" href="#_">Terms and Conditions</a></label>
                </div>
              </div>

              <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign in</button>
              <p className="text-sm font-light text-gray-500">
                Already have an account?<a href="/login" className="font-medium text-blue-600 hover:underline">Login here</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
