import React, { useState } from 'react';
import { FunnelIcon } from "@heroicons/react/24/solid";
import { Drawer } from 'flowbite-react';
import { categories } from './CategoriesBar';
import { useTranslation } from 'react-i18next';

export default function FilterBar({ onCategorySelect }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="flex w-full items-center justify-center md:hidden rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 sm:w-auto">
        <FunnelIcon className="-ms-0.5 me-2 h-4 w-4" />
        Filter
      </button>

      <Drawer style={{ marginLeft: "0px", marginRight: "0px" }} open={isOpen} onClose={handleClose}>
        <Drawer.Header title={t("product-categories")} titleIcon={() => <></>} />
        <Drawer.Items>
          <div className='flex flex-col col-span-1 gap-5'>
            {categories.map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  onCategorySelect(item.title);
                  handleClose();
                }}
                className='flex flex-wrap items-center gap-2 py-3 text-gray-900 text-sm font-semibold'>
                <img src={item.icon} className='w-6 h-auto' alt="" />
                {t(item.title)}
              </button>
            ))}
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
